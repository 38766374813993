import { useMutation, useQuery } from "@apollo/client";
import { Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ASSESSMENTS_SHOW, ASSESSMENTS_UPDATE } from "../../ApiQueries";
import SliderControl from "../SliderControl";
import Wizard, { WizardStep } from "../Wizard";

const MitigationsNew = (props: any) => {
  const { flightId, assessmentId } = useParams();
  const navigate = useNavigate();

  const [stepNumber, setStepNumber] = useState(0);
  const [nextActive, setNextActive] = useState(false);

  let [formContent, setFormContent] = useState<any>({
    text: '',
    score: 0
  });

  const addField = function (name: string, value: any) {
    const updatedValue = {
      [name]: value
    };
    setFormContent((formContent: any) => ({
      ...formContent,
      ...updatedValue
    }));
  };

  const handleChange = function (e: any) {
    if (e.target) {
      const wrapper = e.target.closest("[data-name]");
      const name = wrapper.getAttribute("data-name");
      const type = wrapper.getAttribute("data-type");
      if (type === "int") {
        const parsed = parseInt(e.target.value, 10);
        if (Number.isNaN(parsed)) {
          addField(name, 0);
        } else {
          addField(name, parsed);
        }
      } else {
        addField(name, e.target.value);
      }
    } else {
      console.log("Unknown name", e)
      throw Error("Unknown name");
    }
  };

  const handleSliderChange = function(value: any) {
    addField('score', value);
  };

  const assessmentStatus = useQuery(ASSESSMENTS_SHOW, { variables: { id: parseInt(assessmentId!, 10) } });

  let customMitigations: any[] = [];
  if(assessmentStatus.data) {
    customMitigations = assessmentStatus.data.frat_asmt.custom_mitigations || [];
  }

  const [addMitigation, { loading, error }] = useMutation(ASSESSMENTS_UPDATE);

  const saveMitigation = function () {
    const oldCustomMitigations = customMitigations.map((m) => { return { text: m.text, score: m.score } });
    addMitigation({
      variables: { id: parseInt(assessmentId!, 10), fields: { customMitigations: [...oldCustomMitigations, formContent] } },
      refetchQueries: ['AssessmentsShow'],
      onCompleted: function onCompleted(response) {
        navigate(`/flights/${flightId}/assessments/${assessmentId}/mitigations`);
      }
    });
  };

  useEffect(() => {
    const inputName = steps[stepNumber].name;
    const inputValue = formContent[inputName];
    if (inputValue === 0 || inputValue === "") {
      setNextActive(false)
    } else {
      setNextActive(true);
    }
  }, [stepNumber, formContent]);

  if (loading) return <div>Submitting...</div>;
  if (error) return <div>Submission error! {error.message}</div>;

  const changeStep = function (step: number) {
    setStepNumber(step);
  }

  const steps: Array<WizardStep> = [
    {
      element: <Stack spacing={2}>
        <Typography variant="body1">What's your own mitigation?</Typography>
        <TextField data-name="text" label="Text" variant="outlined" defaultValue={formContent.text} onChange={handleChange} />
      </Stack>,
      name: 'text'
    }, {
      element: <Stack spacing={2}>
        <Typography variant="body1">Give your mitigation a score</Typography>
        <SliderControl data-name="score" defaultValue={formContent.score} minValue={1} maxValue={10} onChange={handleSliderChange} />
      </Stack>,
      name: 'score'
    }
  ]

  return (
    <Wizard steps={steps} stepNumber={stepNumber} showNav={true} onComplete={saveMitigation} onChangeStep={changeStep}
      backLink={`/flights/${flightId}/assessments/${assessmentId}/mitigations`} nextActive={nextActive} />
  );
}

export default MitigationsNew
