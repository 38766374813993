import { useQuery } from '@apollo/client';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { List, ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import { use100vh } from 'react-div-100vh';
import { Link, useNavigate, useParams } from "react-router-dom";
import { FRATS_INDEX } from '../../ApiQueries';

type FratIndexParams = {
    flightId: string | undefined;
}

const FratIndex = (props: any) => {
    const { flightId } = useParams<FratIndexParams>();

    const { loading, error, data } = useQuery(FRATS_INDEX);

    const navigate = useNavigate();

    const h = use100vh();

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <Stack spacing={2} sx={{ padding: 5, boxSizing: 'border-box', maxHeight: (h || '100vh') }}>
            <Link to={`/flights/${flightId}`} color="black"><ArrowBackIosIcon /> <span>Flight</span></Link>
            <Typography variant="body1">Choose FRAT</Typography>
            <Stack sx={{ overflowY: 'scroll' }}>
                <List>
                    {data.frats.map((frat: any) => (
                        <ListItem key={frat.id} onClick={ (e) => navigate(`/flights/${flightId}/frats/${frat.id}`) } disablePadding>
                            <ListItemButton style={{ justifyContent: "space-between" }}>
                                <Stack>
                                    <Typography variant="body2">
                                        {frat.title}
                                    </Typography>
                                </Stack>
                                <ArrowForwardIosIcon />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Stack>
        </Stack>
    );
}

export default FratIndex
