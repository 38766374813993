import { Box, Typography } from '@mui/material';
import SliderControl from '../../SliderControl';

const SliderItem = (props: any) => {
    const { item, onChange, answer } = props;

    const handleChange = function(value: number) {
        onChange(value);
    }

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', overflowY: 'hidden', overflowX: 'hidden' }}>
            <Typography variant="body1">{item.text}</Typography>
            <SliderControl minValue={item.minScore} maxValue={item.maxScore} defaultValue={answer} onChange={handleChange} />
        </Box>
    );
}

export default SliderItem
