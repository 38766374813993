import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const SliderControl = (props: any) => {
  const { minValue, maxValue, defaultValue, onChange } = props;

  const valueDelta = maxValue - minValue;
  const midPoint = Math.floor(minValue + valueDelta / 2);
  const targetInterval = 0.08; // min distance between labels in radians
  const angleInterval = Math.PI / valueDelta; // Show entire range within 180° [aka PI]
  const labelInterval = Math.ceil(targetInterval / angleInterval); // write a label every n steps

  console.log('mid', midPoint);
  console.log('ai', angleInterval);

  const [value, setValue] = useState(midPoint);

  const wheelRef = useRef(null);

  useEffect(() => {
    onChange(value);
  }, [value]);

  useEffect(() => {
    const drawText = function (svg: any, angle: number, r: number, label: number) {
      const svgns = "http://www.w3.org/2000/svg";

      const a = pos(angle, r);

      const t = document.createElementNS(svgns, "text");
      t.innerHTML = label.toString();
      t.addEventListener('click', function () {
        setValue(label);
      });
      t.setAttribute("x", a.x);
      t.setAttribute("y", a.y);
      t.setAttribute("text-anchor", "end");
      t.setAttribute("dominant-baseline", "center");
      t.setAttribute("transform", `rotate(${180 - angle * 180 / Math.PI}, ${a.x}, ${a.y})`);

      svg.appendChild(t);
    }

    const drawLine = function (svg: any, angle: number, r1: number, r2: number) {
      const a = pos(angle, r1);
      const b = pos(angle, r2);

      // console.log(a.x, a.y, b.x, b.y);

      const svgns = "http://www.w3.org/2000/svg";

      const line = document.createElementNS(svgns, "line");

      line.setAttribute("x1", a.x);
      line.setAttribute("y1", a.y);
      line.setAttribute("x2", b.x);
      line.setAttribute("y2", b.y);
      line.setAttribute("stroke", "#000");

      svg.appendChild(line);
    }

    const pos = function (angle: number, r: number): any {
      return {
        x: 500 + r * Math.cos(angle),
        y: 500 - r * Math.sin(angle)
      };
    }

    let label = Math.floor(maxValue + valueDelta / 2); // `label` is the current label, incremented by 1
    for (let i = 0; i < Math.PI * 2; i += angleInterval) { // i increments in radians
      if (label >= minValue && label <= maxValue) {
        if (label % labelInterval === 0) {
          drawLine(wheelRef.current, i, 450, 460); // 10px line + label
          drawText(wheelRef.current, i + 0.012, 470, label);
        } else {
          drawLine(wheelRef.current, i, 450, 455); // 5px line
        }
      }
      label--;
    }
  }, []);

  // Rotate wheel when value changes
  useEffect(() => {
    // red line is x=500, y=200
    // angle is 21.8°, which is 0.3804818 rad
    console.log('val', value);
    const baseRotation = 21.8;
    const midPointOffset = midPoint - value;
    const rotationDegree = baseRotation + (midPointOffset * angleInterval) * 180 / Math.PI;
    (wheelRef.current as any).style.transform = `rotate(${rotationDegree}deg)`;
  }, [value]);

  useEffect(() => {
    setValue(defaultValue || midPoint);
  }, []);

  return (
    <Box>
      <Typography variant="h3">{value}</Typography>
      <Box width="100vh" height="100vh" style={{ position: 'absolute', top: '20vh' }}>
        <svg xmlns="http://www.w3.org/2000/svg"
          style={{ position: 'absolute', left: '20%', top: 0, zIndex: -1 }}
          width="100vh" height="100vh" viewBox="0 0 1000 1000">
          <line x1="500" y1="500" x2="0" y2="300" stroke="red" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg"
          ref={wheelRef} className='wheel'
          style={{ position: 'absolute', left: '20%', top: 0 }}
          width="100vh" height="100vh" viewBox="0 0 1000 1000">
        </svg>
      </Box>
    </Box>
  );
}

export default SliderControl
