import { useQuery } from '@apollo/client';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Link, List, ListItem, ListItemButton, Stack, Tab, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useNavigate } from "react-router-dom";
import { FLIGHTS_INDEX, FRATS_INDEX } from '../../ApiQueries';
import { Auth } from '../../Auth';
import OrganizationSelector from '../organizations/OrganizationSelector';

const isInFuture = function(flight: any) {
  if(flight.id === 1) {
    return true;
  }
  return (new Date(flight.date).setUTCHours(0,0,0,0)) >= (new Date().setUTCHours(0, 0, 0, 0));
}

const FlightIndex = (props: any) => {
    const { loading, error, data } = useQuery(FLIGHTS_INDEX);
    const fratResult = useQuery(FRATS_INDEX);
    const [tabIndex, setTabIndex] = useState("1");

    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
      const a = new Auth();
      a.check(function onSucess() {
        if(!loggedIn) {
          setLoggedIn(true);
        }
      }, function onFailure() {
        if(loggedIn) {
          setLoggedIn(false);
        }
      });
    }, [loggedIn]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
      setTabIndex(newValue);
    };

    useEffect(() => {
      if(loggedIn) {
        setTabIndex("1");
      } else {
        setTabIndex("2");
      }
    }, [loggedIn]);

    const navigate = useNavigate();

    const h = use100vh();

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Loading error</p>;

    const toHHMM = function (mins: number) {
        let hours   = Math.floor(mins / 60);
        let minutes = Math.floor(mins - (hours * 60));
        let str = "";
        if (hours   < 10) { str += "0" + hours; } else { str += hours }
        str += ":";
        if (minutes < 10) { str += "0" + minutes; } else { str += minutes }
        return str;
    }

    const openLogin = function() {
      window.location.href = `${process.env.REACT_APP_BACKEND_URL!}/auth?continue=${encodeURIComponent(window.location.href)}`;
    }

    return (
        <Stack sx={{ padding: 5, boxSizing: 'border-box', maxHeight: (h || '100vh') }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <img className="logo" src="logo192.png" alt="Airplane outline as logo" />
            {loggedIn &&
              <OrganizationSelector/>
            }
            {!loggedIn &&
              <Typography variant="caption">Free Mode</Typography>
            }
          </Stack>
          <TabContext value={tabIndex}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange}>
                <Tab label="Flights" value="1"/>
                <Tab label="FRATs" value="2"/>
                <Tab label="Archive" value="3"/>
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: 0, overflowY: 'scroll' }}>
              {!loggedIn &&
                <Stack sx={{ paddingTop: 5 }}>
                  <Typography variant="caption">Flights can only be added after logging in.</Typography>
                  <Button sx={{ marginTop: 5 }} variant="contained" onClick={openLogin}>Login</Button>
                  <Typography sx={{ marginTop: 3, fontStyle: 'italic' }} variant="caption">No Login yet? Get your own account <Link sx={{ display: 'inline' }} href="https://ngft.com/frat-login-page/">here</Link>.</Typography>
                </Stack>
              }
              {loggedIn &&
                <List>
                    {data && data.flights.filter((flight: any) => { return !flight.archived && isInFuture(flight) }).map((flight: any) => (
                        <ListItem key={flight.id} onClick={ (e) => navigate(`/flights/${flight.id}`) } disablePadding>
                            <ListItemButton style={{ justifyContent: "space-between" }}>
                                <Stack spacing={1}>
                                    <Typography variant="body2">
                                        {flight.description}
                                    </Typography>
                                    <Typography variant="overline" sx={{ color: 'n050.main' }}>
                                        {flight.date}, {toHHMM(flight.eobt)} {flight.reg}: {flight.dep}-{flight.dest}
                                    </Typography>
                                </Stack>
                                <ArrowForwardIosIcon />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
              }
              {loggedIn &&
                <Button variant="contained" onClick={ (e) => navigate(`/flights/new`) } sx={{ position: 'absolute', right: 50, bottom: 50 }}>New flight</Button>
              }
            </TabPanel>
            <TabPanel value="2" sx={{ padding: 0, overflowX: 'scroll' }}>
              {loggedIn &&
                <Stack sx={{ paddingTop: 5 }}>
                  <Typography variant="caption">Select a flight to see the FRATs.</Typography>
                </Stack>
              }
              {!loggedIn &&
                <List>
                  {fratResult.data && fratResult.data.frats.map((frat: any) => (
                      <ListItem key={frat.id} onClick={ (e) => navigate(`/flights/0/frats/${frat.id}`) } disablePadding>
                          <ListItemButton style={{ justifyContent: "space-between" }}>
                              <Stack>
                                  <Typography variant="body2">
                                      {frat.title}
                                  </Typography>
                              </Stack>
                              <ArrowForwardIosIcon />
                          </ListItemButton>
                      </ListItem>
                  ))}
                </List>
              }
            </TabPanel>
            <TabPanel value="3" sx={{ padding: 0, overflowX: 'scroll' }}>
              {!loggedIn &&
                <Stack sx={{ paddingTop: 5 }}>
                  <Typography variant="caption">The archive is only visible after logging in.</Typography>
                  <Button sx={{ marginTop: 5 }} variant="contained" onClick={openLogin}>Login</Button>
                  <Typography sx={{ marginTop: 3, fontStyle: 'italic' }} variant="caption">No Login yet? Get your own account <Link sx={{ display: 'inline' }} href="https://ngft.com/frat-login-page/">here</Link>.</Typography>
                </Stack>
              }
              {loggedIn &&
                <List>
                    {data && data.flights.filter((flight: any) => { return flight.archived || !isInFuture(flight) }).map((flight: any) => (
                        <ListItem key={flight.id} onClick={ (e) => navigate(`/flights/${flight.id}`) } disablePadding>
                            <ListItemButton style={{ justifyContent: "space-between" }}>
                                <Stack spacing={1}>
                                    <Typography variant="body2">
                                        {flight.description}
                                    </Typography>
                                    <Typography variant="overline" sx={{ color: 'n050.main' }}>
                                        {flight.date}, {toHHMM(flight.eobt)} {flight.reg}: {flight.dep}-{flight.dest}
                                    </Typography>
                                </Stack>
                                <ArrowForwardIosIcon />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
              }
            </TabPanel>
          </TabContext>
        </Stack>
    );
}

export default FlightIndex
export { isInFuture };

