import { gql } from "@apollo/client";

export const FRAT_FIELDS = gql`
  fragment FratFields on Frat {
    id
    org {
      id
      name
    }
    type
    demo
    title
    slug
    revision
    status
    items {
      id
      type
      index
      group
      mitigations {
        id
        text
        score
      }
      text
      required
      multiple
      options {
        id
        index
        text
        score
        mitigations {
          id
          text
          score
        }
        flags
      }
      sliderThresholds {
        id
        index
        minValue
        maxValue
        score
        mitigations {
          id
          text
          score
        }
      }
      minScore
      maxScore
      flags
      threshold
      score
    }
    groupIndexes
    modifiers {
      id
      flags
      score
    }
    riskLevels {
      id
      threshold
      title
      color
      flags
      mitigations {
        id
        text
        score
      }
    }
  }
`;

export const ASSESSMENT_FIELDS = gql`
  ${FRAT_FIELDS}
  fragment AssessmentFields on FratAsmt {
    id
    mitigations
    customItems {
      score
      text
    }
    custom_mitigations {
      text
      score
    }
    inputs {
      option {
        text
        score
        mitigations {
          id
          text
          score
        }
      }
      score
      item {
        type
        score
        sliderThresholds {
          id
          index
          minValue
          maxValue
          score
          mitigations {
            id
            text
            score
          }
        }
      }
    }
    frat {
      ...FratFields
    }
    flight {
      id
      date
    }
  }
`;

export const FLIGHT_FIELDS = gql`
  ${ASSESSMENT_FIELDS}
  fragment FlightFields on Flight {
    id
    description
    date
    reg
    dep
    dest
    altn
    eobt
    eet
    pob
    pic
    archived
    assessments {
      ...AssessmentFields
    }
  }
`;
