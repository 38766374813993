import { useQuery } from '@apollo/client';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Stack, Typography } from "@mui/material";
import { use100vh } from 'react-div-100vh';
import { Link, useNavigate, useParams } from "react-router-dom";
import { FLIGHTS_SHOW } from '../../ApiQueries';
import AssessmentIndex from '../assessments/AssessmentIndex';
import { isInFuture } from './FlightIndex';

const FlightShow = (props: any) => {
  const { flightId } = useParams();

  const navigate = useNavigate();

  const { loading, error, data } = useQuery(FLIGHTS_SHOW, { variables: { flightId: parseInt(flightId!, 10) } });

  const h = use100vh();

  if (loading) return <p>Loading...</p>;
  if (error) console.log(error);

  return (
    <Stack spacing={2} sx={{ padding: 5, boxSizing: 'border-box', maxHeight: (h || '100vh') }}>
      {data &&
        <>
          <Link to="/flights" color="black"><ArrowBackIosIcon /> <span>Flights</span></Link>
          <Typography variant="body1">{data.flight.description}</Typography>
          <AssessmentIndex flight={data.flight} />
          {isInFuture(data.flight) &&
            <Button variant="contained" onClick={(e) => navigate(`/flights/${flightId}/frats`)} sx={{ position: 'absolute', right: 50, bottom: 50 }}>New assessment</Button>
          }
        </>
      }
    </Stack>
  );
}

export default FlightShow
