import { gql } from "@apollo/client";
import { ASSESSMENT_FIELDS, FLIGHT_FIELDS, FRAT_FIELDS } from "./ApiFragments";

export const ASSESSMENTS_SHOW = gql`
  ${ASSESSMENT_FIELDS}
  query AssessmentsShow($id: Int!) {
    frat_asmt(id: $id) {
      ...AssessmentFields
    }
  }
`;

export const FLIGHTS_INDEX = gql`
  ${FLIGHT_FIELDS}
  query FlightsIndex {
    flights {
      ...FlightFields
    }
  }
`;

export const FLIGHTS_SHOW = gql`
  ${FLIGHT_FIELDS}
  query FlightsShow($flightId: Int!) {
    flight(id: $flightId) {
      ...FlightFields
    }
  }
`;

export const FLIGHTS_CREATE = gql`
  mutation FlightsCreate($description: String!, $date: Date!, $reg: String!, $dep: String!,
                                          $dest: String!, $altn: String! $eobt: Int!, $eet: Int!, $pob: Int!, $pic: String!) {
    newFlight(description: $description, date: $date, reg: $reg, dep: $dep,
                dest: $dest, altn: $altn, eobt: $eobt, eet: $eet, pob: $pob, pic: $pic) {
      id
    }
  }
`;

export const FRATS_INDEX = gql`
  query FratsIndex {
    frats {
        id
        type
        title
        revision
        status
        demo
        org {
        id
        }
    }
    fratImports {
        fratId
        orgId
    }
  }
`;

export const FRATS_SHOW = gql`
  ${FRAT_FIELDS}
  query FratsShow($fratId: Int!) {
    frat(id: $fratId) {
      ...FratFields
    }
  }
`;

export const ASSESSMENTS_CREATE = gql`
  mutation AssessmentsCreate($fratId: Int!, $orgId: Int!, $flightId: Int!) {
    newFratAsmt(fratId: $fratId, orgId: $orgId, flightId: $flightId) {
        id
    }
  }
`;

export const ASSESSMENT_INPUTS_CREATE = gql`
  mutation AssessmentInputsCreate($asmtId: Int!, $itemId: Int!, $selected: Boolean, $optionIds: [Int!], $fields: FratInputInput!) {
    setFratAsmtInput(asmtId: $asmtId, itemId: $itemId, selected: $selected, optionIds: $optionIds, fields: $fields) {
      _
    }
  }
`;

export const ASSESSMENTS_UPDATE = gql`
  mutation AssessmentsUpdate($id: Int!, $fields: FratAsmtInput!) {
    updateFratAsmt(id: $id, fields: $fields) {
      id
    }
  }
`;

export const ORGANIZATIONS_INDEX = gql`
  query OrganizationsIndex {
    myOrgs {
      id
      name
    }
  }
`;
