import { useMutation, useQuery } from '@apollo/client';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Checkbox, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { Link, useNavigate, useParams } from "react-router-dom";
import { ASSESSMENTS_SHOW, ASSESSMENTS_UPDATE } from '../../ApiQueries';
import { getPossibleMitigations } from '../assessments/AssessmentShow';

const MitigationsIndex = (props: any) => {
    const { flightId, assessmentId } = useParams();

    const [fratTitle, setFratTitle] = useState("");
    const [enableMitigation, enableMitigationStatus] = useMutation(ASSESSMENTS_UPDATE);
    const [enabledMitigationIds, setEnabledMitigationIds] = useState<number[]>([]);
    const [possibleMitigations, setPossibleMitigations] = useState<any[]>([]);
    const [customMitigations, setCustomMitigations] = useState<any[]>([]);

    const navigate = useNavigate();

    const { loading, error, data } = useQuery(ASSESSMENTS_SHOW, { variables: { id: parseInt(assessmentId!, 10) } });

    useEffect(() => {
      if(loading) { return }
      const assessment = data.frat_asmt;
      setEnabledMitigationIds(assessment.mitigations || []);
      setFratTitle(assessment.frat.title);

      const possible = getPossibleMitigations(assessment.inputs);
      setPossibleMitigations(possible);

      setCustomMitigations(assessment.custom_mitigations || []);
    }, [data]);

    const h = use100vh();

    if (loading) return <p>Loading...</p>;
    if (error) console.log(error);

    if (enableMitigationStatus.error) return <p>EnableMitigation error :(</p>;

    const handleChange = function(id: number) {
      let after = [];
      if(enabledMitigationIds.indexOf(id) > -1) {
        after = enabledMitigationIds.filter((mid: number) => mid !== id)
      } else {
        after = [...enabledMitigationIds, id];
      }
      setEnabledMitigationIds(after);
      enableMitigation({
        variables: { id: parseInt(assessmentId!, 10), fields: { mitigations: after } },
        refetchQueries: ['AssessmentsShow']
      });
    }

    return (
        <Stack spacing={2} sx={{ padding: 5, boxSizing: 'border-box', maxHeight: (h || '100vh') }}>
          <Link to={`/flights/${flightId}/assessments/${assessmentId}`} color="black"><ArrowBackIosIcon /> <span>Risk score</span></Link>
          <Typography variant="body1">{fratTitle}</Typography>
          <Stack sx={{ overflowY: 'scroll' }}>
            <List>
              {possibleMitigations.length === 0 &&
                  <Typography variant="caption">No mitigations available.</Typography>
              }
              {possibleMitigations.map((mitigation: any) => (
                  <ListItem
                    key={mitigation.id}
                    secondaryAction={<Checkbox edge="end" checked={enabledMitigationIds.indexOf(mitigation.id) > -1} onClick={() => handleChange(mitigation.id)} />}>
                      <ListItemButton style={{ justifyContent: "space-between" }}
                                      sx={{ border: '2px solid grey' }}
                                      onClick={() => handleChange(mitigation.id)}>
                        <ListItemText secondary={mitigation.text}/>
                      </ListItemButton>
                  </ListItem>
              ))}
              {customMitigations.map((mitigation: any, index: number) => (
                  <ListItem
                    key={parseInt(assessmentId!, 10) * 1000 + index}
                    secondaryAction={<Checkbox edge="end" checked={true} />}>
                      <ListItemButton style={{ justifyContent: "space-between" }}
                                      sx={{ border: '2px solid grey' }}>
                        <ListItemText secondary={mitigation.text}/>
                      </ListItemButton>
                  </ListItem>
              ))}
              <ListItem
                key={0}
                secondaryAction={<ArrowForwardIcon onClick={() => navigate(`/flights/${flightId}/assessments/${assessmentId}/mitigations/new`)} />}>
                  <ListItemButton style={{ justifyContent: "space-between" }}
                                  sx={{ border: '2px solid grey' }}
                                  onClick={() => navigate(`/flights/${flightId}/assessments/${assessmentId}/mitigations/new`)}>
                    <ListItemText secondary="Own mitigation" />
                  </ListItemButton>
              </ListItem>
            </List>
            <Stack direction="row" justifyContent="flex-end" sx={{ marginTop: 3 }}>
              <Button variant="contained" onClick={() => navigate(`/flights/${flightId}/assessments/${assessmentId}`)}>Finish Mitigation</Button>
            </Stack>
          </Stack>
        </Stack>
    );
}

export default MitigationsIndex
