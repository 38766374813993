import { useQuery } from '@apollo/client';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ASSESSMENTS_SHOW } from '../../ApiQueries';
import { isLoggedIn } from '../../Auth';
import { isInFuture } from '../flights/FlightIndex';

const getPossibleMitigations = function(inputs: any[]): any[] {
  let possible: any[] = [];
  for(const input of inputs) {
    if(input.item.type === 'slider') {
      // for the slider, the value is saved in the score field!
      const range = (input.item.sliderThresholds || []).find((range: any) => input.score >= range.minValue && input.score <= range.maxValue);
      if(range && range.mitigations) {
        possible = possible.concat(range.mitigations);
      }
    } else if(input.mitigations) {
      possible = possible.concat(input.mitigations);
    } else if(input.option && input.option.mitigations) {
      possible = possible.concat(input.option.mitigations);
    }
  }
  return possible;
}

const findRiskLevel = function(riskLevels: any[], scoreSum: number) {
  if(riskLevels.length === 0) {
    return { ri: 0, rn: '' }
  }
  riskLevels.sort((a, b) => b.threshold - a.threshold);
  let riskLevel = riskLevels.find((level) => {
    return scoreSum > level.threshold;
  });
  if(typeof riskLevel === 'undefined') {
    riskLevel = riskLevels[riskLevels.length - 1];
  }
  const ri = Math.min(riskLevels.length - riskLevels.indexOf(riskLevel), 3);
  let rn = riskLevel.title;
  return { ri: ri, rn: rn };
}

const calculateMaxScore = function(frat: any) {
  let scoreMax = 0;
  for(const item of frat.items) {
    if (item.type === 'slider') {
      const scores = item.sliderThresholds.map((range: any) => range.score);
      scoreMax += Math.max(...scores);
    } else if (item.type === 'select') {
      const scores = item.options.map((option: any) => option.score);
      scoreMax += Math.max(...scores);
    } else if (item.type === 'tag') {
      scoreMax += item.score;
    } else {
      throw Error(`No max score calc for item ${item.type}`);
    }
  }
  return scoreMax;
}

const calculateMitigationScore = function(assessment: any) {
  const enableds = [];
  if(assessment.mitigations) {
    const possible = getPossibleMitigations(assessment.inputs);
    for(const enabledId of assessment.mitigations) {
      enableds.push(possible.find((p) => p.id === enabledId));
    }
  }

  let mitigationSum = 0;
  for(const enabled of enableds) {
    mitigationSum += enabled.score;
  }
  for(const customMitigation of (assessment.custom_mitigations || [])) {
    mitigationSum += customMitigation.score;
  }
  return mitigationSum;
}

const calculateScore = function(assessment: any) {
  let scoreSum = 0;
  for(const input of assessment.inputs) {
    if(input.item.type === 'slider') {
      // for the slider, the value is saved in the score field!
      const range = (input.item.sliderThresholds || []).find((range: any) => input.score >= range.minValue && input.score <= range.maxValue);
      if(range) {
        scoreSum += range.score;
      }
    } else if(input.score == null && input.option == null) {
      scoreSum += input.item.score;
    } else if(input.score !== null) {
      scoreSum += input.score;
    } else if(input.option.score !== null) {
      scoreSum += input.option.score;
    } else {
      throw Error(`No score calc for asmt ${assessment.id}`);
    }
  }
  for(const item of (assessment.customItems || [])) {
    scoreSum += item.score;
  }
  return scoreSum;
}

const AssessmentShow = (props: any) => {
  const { flightId, assessmentId } = useParams();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(ASSESSMENTS_SHOW, { variables: { id: parseInt(assessmentId!, 10) } });

  const [score, setScore] = useState(0);
  const [unmitigatedScore, setUnmitigatedScore] = useState(0);
  const [riskLevels, setRiskLevels] = useState<any[]>([]);

  useEffect(() => {
    if(loading) { return }

    const assessment = data.frat_asmt;
    const frat = assessment.frat;

    const scoreMax = calculateMaxScore(frat);
    const scoreSum = calculateScore(assessment);
    const mitigationSum = calculateMitigationScore(assessment);

    setUnmitigatedScore(Math.round(scoreSum / scoreMax * 100));
    setScore(Math.round((scoreSum - mitigationSum) / scoreMax * 100));

    let rl: any[] = [...(frat.riskLevels)];
    if(rl.length === 0) {
      rl = [
        { title: 'No Risk', threshold: scoreMax * 0 },
        { title: 'Low', threshold: scoreMax * 0.01 },
        { title: 'Moderate', threshold: scoreMax * 0.1 },
        { title: 'Substantial', threshold: scoreMax * 0.5 },
        { title: 'High', threshold: scoreMax * 0.7 }
      ]
    }

    setRiskLevels(rl);
  }, [data, loading]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Loading error</p>;

  let actionButtons = <>
    <Button variant="outlined" onClick={() => navigate('/flights')}>Cancel flight</Button>
    <Button variant="contained" onClick={() => navigate('/flights')} sx={{ backgroundColor: "green.main" }} >Fly</Button>
  </>;
  if(score >= 10) {
    actionButtons = <>
      <Button variant="outlined" onClick={() => navigate('/flights')}>Fly anyway</Button>
      <Button variant="outlined" onClick={() => navigate('/flights')}>Cancel flight</Button>
      <Button variant="outlined" onClick={() => navigate(`/flights/${flightId}/assessments/${assessmentId}/mitigations`)}>Mitigate</Button>
    </>;
  }
  if(score >= 50) {
    actionButtons = <>
      {unmitigatedScore !== score &&
        <Button variant="outlined" onClick={() => navigate('/flights')}>Fly anyway</Button>
      }
      <Button variant="outlined" onClick={() => navigate('/flights')}>Cancel flight</Button>
      <Button variant="contained" onClick={() => navigate(`/flights/${flightId}/assessments/${assessmentId}/mitigations`)} sx={{ backgroundColor: "orange.main" }}>Mitigate</Button>
    </>;
  }
  if(score >= 70) {
    actionButtons = <>
      {unmitigatedScore !== score &&
        <Button variant="outlined" onClick={() => navigate('/flights')}>Fly anyway</Button>
      }
      <Button variant="outlined" onClick={() => navigate(`/flights/${flightId}/assessments/${assessmentId}/mitigations`)}>Mitigate</Button>
      <Button variant="contained" onClick={() => navigate('/flights')} sx={{ backgroundColor: "red.main" }} >Cancel flight</Button>
    </>;
  }

  return (
    <Stack sx={{ padding: 5, boxSizing: 'border-box', height: '100%', justifyContent: 'space-between', maxWidth: '100vw' }}>
      <Link to={isLoggedIn() ? `/flights/${flightId}` : '/flights'} color="black"><ArrowBackIosIcon /> <span>Assessments</span></Link>
      {unmitigatedScore !== score &&
        <Stack sx={{ paddingTop: 5, paddingBottom: 5 }} spacing={2}>
          <Typography variant="overline">Before Mitigation</Typography>
          <Typography variant="h4">{unmitigatedScore}% {findRiskLevel(riskLevels, unmitigatedScore).rn}</Typography>
        </Stack>
      }
      <Stack sx={{ paddingTop: (unmitigatedScore === score) ? 5 : 0, paddingBottom: 5 }} spacing={2}>
        {unmitigatedScore !== score &&
          <Typography variant="overline">Your new Risk Score</Typography>
        }
        <Stack>
          <Typography variant="h2">{score}%</Typography>
          <Typography variant="h2">{findRiskLevel(riskLevels, score).rn}</Typography>
        </Stack>
      </Stack>
      {data && data.frat_asmt && data.frat_asmt.flight && isInFuture(data.frat_asmt.flight) &&
        <Stack spacing={2} sx={{ alignItems: 'flex-end' }}>
          {actionButtons}
        </Stack>
      }
    </Stack>
  );
}

export default AssessmentShow
export { getPossibleMitigations, calculateScore, calculateMaxScore, calculateMitigationScore, findRiskLevel };

  function getLoggedIn() {
    throw new Error('Function not implemented.');
  }

