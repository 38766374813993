import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';

const CustomRiskItem = (props: any) => {
    const { answer, item, onChange, onEnableDisable } = props;

    const [textVisible, setTextVisible] = useState(false);
    const [value, setValue] = useState('none');

    const enable = function() {
        onEnableDisable(true);
        setTextVisible(true);
        setValue('yes');
    }

    const disable = function() {
        onEnableDisable(false);
        setValue('no');
    };

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'  }}>
            <Typography variant="body1">{item.text}</Typography>

            {(!item.enabled && !textVisible) &&
                <Stack sx={{ alignItems: 'flex-end' }}>
                    <Button sx={{ mt: 2, textTransform: 'none' }} disableElevation
                        variant={typeof(value) === 'undefined' || value === 'yes' ? 'contained' : 'outlined' }
                        onClick={() => { enable() }}>
                        Yes
                    </Button>
                    <Button sx={{ mt: 2, textTransform: 'none' }} disableElevation
                        variant={typeof(value) === 'undefined' || value === 'no' ? 'contained' : 'outlined' }
                        onClick={() => { disable() }}>
                        No
                    </Button>
                </Stack>
            }

            {(item.enabled || textVisible) &&
                <TextField variant="outlined" label="Additional risk" defaultValue={answer} onChange={(e: any) => onChange(e.target.value)} />
            }
        </Box>
    );
}

export default CustomRiskItem
