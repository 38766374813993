import { Box, Button, Stack, Typography } from '@mui/material';

const TagItem = (props: any) => {
    const { answer, item, onChange } = props;

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'  }}>
            <Typography variant="body1">{item.text}</Typography>
            <Stack sx={{ alignItems: 'flex-end' }}>
                <Button sx={{ mt: 2, textTransform: 'none' }} disableElevation
                    variant={typeof(answer) === 'undefined' || answer ? 'contained' : 'outlined' }
                    onClick={() => { onChange('yes') }}>
                    Yes
                </Button>
                <Button sx={{ mt: 2, textTransform: 'none' }} disableElevation
                    variant={typeof(answer) === 'undefined' || !answer ? 'contained' : 'outlined' }
                    onClick={() => { onChange('no') }}>
                    No
                </Button>
            </Stack>
        </Box>
    );
}

export default TagItem
