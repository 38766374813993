import { Box, Button, Stack, Typography } from '@mui/material';

const SingleChoiceItem = (props: any) => {
    const { answer, item, onChange } = props;

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'  }}>
            <Typography variant="body1">{item.text}</Typography>
            <Stack spacing={2} alignItems="flex-end">
                {item.options.map((option: any) => (
                    <Button key={option.id} sx={{ mt: 2, textTransform: 'none' }} variant={ typeof(answer) === 'undefined' || answer === option.id ? 'contained' : 'outlined' } disableElevation
                    onClick={() => { onChange(option.id) }}>
                        {option.text}
                    </Button>
                ))}
            </Stack>
        </Box>
    );
}

export default SingleChoiceItem
