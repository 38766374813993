import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LinearProgress, List, ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { calculateMaxScore, calculateMitigationScore, calculateScore } from './AssessmentShow';

const AssessmentIndex = (props: any) => {
    const navigate = useNavigate();

    const flight = props.flight;

    const getProgress = function(asmt: any) {
        return asmt.inputs.length / asmt.frat.items.length * 100;
    };

    return (
        <Stack sx={{ overflowY: 'scroll' }}>
            <List>
                {flight.assessments.length === 0 &&
                    <Typography variant="caption">No assessments yet.</Typography>
                }
                {flight.assessments.map((asmt: any) => (
                    <ListItem key={asmt.id} onClick={ (e) => navigate(`/flights/${flight.id}/assessments/${asmt.id}`) } disablePadding>
                        <ListItemButton style={{ justifyContent: "space-between" }}>
                            <Stack spacing={2}>
                                <Stack spacing={1}>
                                    <Typography variant="body2">
                                        {asmt.frat.title}
                                    </Typography>
                                    <Typography variant="overline" sx={{ color: 'n050.main' }}>
                                        Risk Score: {Math.round((calculateScore(asmt) - calculateMitigationScore(asmt)) / calculateMaxScore(asmt.frat) * 100)}%
                                    </Typography>
                                </Stack>
                                <LinearProgress variant="determinate" value={getProgress(asmt)} />
                            </Stack>
                            <ArrowForwardIosIcon />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
}

export default AssessmentIndex
