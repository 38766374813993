import { useQuery } from '@apollo/client';
import { MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { ORGANIZATIONS_INDEX } from '../../ApiQueries';

const getOrgId = function(): number {
  const val = localStorage.getItem('current-org');
  return val ? parseInt(val, 10) : 0;
}

const setOrgId = function(id: number) {
  localStorage.setItem('current-org', id.toString());
}

const OrganizationSelector = (props: any) => {
  const { loading, error, data } = useQuery(ORGANIZATIONS_INDEX);

  const [currentOrgId, setCurrentOrgId] = useState(getOrgId());

  useEffect(() => {
    setOrgId(currentOrgId);
  }, [currentOrgId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const handleChange = function (e: any) {
    setCurrentOrgId(e.target.value);
  }

  return (
    <Select onChange={handleChange} value={currentOrgId || ''}>
      {data.myOrgs.map((org: any) => (
        <MenuItem key={org.id} value={org.id}>
          {org.name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default OrganizationSelector
export { getOrgId, setOrgId };
