import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Stack, Typography } from '@mui/material';
import { use100vh } from 'react-div-100vh';
import { Link } from "react-router-dom";

interface WizardProps {
  steps: Array<WizardStep>;
  onComplete: () => void;
  onChangeStep: (step: number) => void;
  backLink: string;
  showNav: boolean;
  stepNumber: number;
  nextActive: boolean;
}

export interface WizardStep {
  element: any;
  name: string;
  item?: any;
}

const Wizard = (props: WizardProps) => {
  const { steps, stepNumber, nextActive } = props;
  const step = steps[stepNumber];

  const currentPage = stepNumber + 1;
  const totalPages = steps.length;

  const h = use100vh();

  if(steps.length === 0) {
    return <div></div>;
  }

  return (
    <Stack style={{ height: (h || '100vh') }} spacing={5} sx={{ padding: 5, boxSizing: 'border-box' }}>
      <Link to={props.backLink} color="primary"><ArrowBackIosIcon /></Link>
      <Box sx={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
        { step.element }
      </Box>
      {props.showNav &&
        <Stack spacing={1}>
          <Typography variant="overline">{currentPage}/{totalPages}</Typography>
          <Stack direction="row" spacing={2}>
            <Button disabled={currentPage <= 1} variant="contained" sx={{ width: '40px', height: '40px', borderRadius: '2px' }} onClick={ () => { props.onChangeStep(stepNumber - 1); }}><KeyboardArrowUpIcon/></Button>
            {currentPage < totalPages &&
              <Button disabled={!nextActive} variant="contained" sx={{ width: '40px', height: '40px', borderRadius: '2px' }} onClick={ () => { props.onChangeStep(stepNumber + 1); }}><KeyboardArrowDownIcon/></Button>
            }
            {currentPage >= totalPages &&
              <Button disabled={!nextActive} variant="contained" sx={{ height: '40px', borderRadius: '2px' }} onClick={ () => { props.onComplete(); }}>Complete</Button>
            }
          </Stack>
        </Stack>
      }
    </Stack>);
}

export default Wizard
