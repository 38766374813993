import { Box, Button, Checkbox, Stack, Typography } from '@mui/material';
import { useState } from 'react';

const MultipleChoiceItem = (props: any) => {
    const { item, onChange, answer } = props;

    const [enabledOptions, setEnabledOptions] = useState(answer || []);

    const handleChange = function(optionId: number) {
        let newOptions = [...enabledOptions];
        if(enabledOptions.indexOf(optionId) > -1) {
            newOptions = enabledOptions.filter((id: number) => id !== optionId);
        } else {
            newOptions = [...enabledOptions, optionId];
        }
        setEnabledOptions(newOptions);
        onChange(newOptions);
    };

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'  }}>
            <Typography variant="body1">{item.text}</Typography>
            <Stack spacing={2} alignItems="flex-end">
                {item.options.map((option: any) => (
                    <Stack key={option.id} direction="row" alignItems="center" sx={{ mt: 2 }}>
                        <Button sx={{ textTransform: 'none' }} variant={enabledOptions.indexOf(option.id) > -1 ? 'contained' : 'outlined' } disableElevation
                            onClick={() => handleChange(option.id)}>
                            {option.text}
                        </Button>
                        <Checkbox onClick={() => handleChange(option.id)} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={enabledOptions.indexOf(option.id) > -1}/>
                    </Stack>
                ))}
            </Stack>
        </Box>
    );
}

export default MultipleChoiceItem
