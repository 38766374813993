import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ASSESSMENTS_CREATE, ASSESSMENTS_UPDATE, ASSESSMENT_INPUTS_CREATE, FRATS_SHOW } from '../../ApiQueries';
import { isLoggedIn } from '../../Auth';
import { getOrgId } from '../organizations/OrganizationSelector';
import Wizard, { WizardStep } from '../Wizard';
import CustomRiskItem from './items/CustomRiskItem';
import MultipleChoiceItem from './items/MultipleChoiceItem';
import SingleChoiceItem from './items/SingleChoiceItem';
import SliderItem from './items/SliderItem';
import TagItem from './items/TagItem';

type FratShowParams = {
  fratId: string | undefined;
  flightId: string | undefined;
}

type NumberAnyMap = {
  [key: number]: any
}

type CustomRisk = {
  score: number;
  text: string;
}

const FratShow = (props: any) => {
  const { fratId, flightId } = useParams<FratShowParams>();

  const navigate = useNavigate();

  const [createAsmt, createAsmtStatus] = useMutation(ASSESSMENTS_CREATE);
  const [createAsmtInput, createAsmtInputStatus] = useMutation(ASSESSMENT_INPUTS_CREATE);
  const [updateAsmt, updateAsmtStatus] = useMutation(ASSESSMENTS_UPDATE);
  const [stepNumber, setStepNumber] = useState(0);
  const [steps, setSteps] = useState<any[]>([]);

  const [nextActive, setNextActive] = useState(false);

  const [asmtId, setAsmtId] = useState(0);

  const [wizardAnswers, setWizardAnswers] = useState<NumberAnyMap>({});
  const [customRisks, setCustomRisks] = useState<CustomRisk[]>([]);

  const orgId = getOrgId();

  useEffect(() => {
    createAsmt({
      variables: { fratId: parseInt(fratId!, 10), orgId, flightId: parseInt(flightId!, 10) },
      refetchQueries: ['FlightsIndex'],
      onCompleted: function onCompleted(response) {
        setAsmtId(response.newFratAsmt.id);
      }
    });
  }, []);

  const getFratStatus = useQuery(FRATS_SHOW, {
    variables: { fratId: parseInt(fratId!, 10) }
  });

  useEffect(() => {
    if(getFratStatus.data) {
      const frat = getFratStatus.data.frat;
      const s: Array<WizardStep> = frat.items.slice().sort((a: any, b: any) => {
        if(frat.groupIndexes.indexOf(a.group) === -1) {
          return 1;                                               // a has no groupIndex
        } else if(frat.groupIndexes.indexOf(b.group) === -1) {
          return -1;                                              // b has no groupIndex
        } else {
          return (100 * frat.groupIndexes.indexOf(a.group) + a.index) - (100 * frat.groupIndexes.indexOf(b.group) + b.index);
        }
      }).map((item: any) => {
        return {
          element: getComponent(item),
          name: `step-${item.id}`,
          item: item
        };
      });
      const c: Array<WizardStep> = customRisks.map((risk: CustomRisk, index: number) => {
        const item1 = {
          type: 'customRisk',
          text: 'Do you have an additional risk to add?',
          enabled: true,
          id: `custom-text-${index}`
        };
        const item2 = {
          type: 'slider',
          text: 'Give your risk a score',
          id: `custom-score-${index}`,
          minScore: 1,
          maxScore: 10
        };
        return [{
          element: getComponent(item1),
          name: `custom-text-${index}`,
          item: item1
        }, {
          element: getComponent(item2),
          name: `custom-score-${index}`,
          item: item2
        }];
      }).flat();
      const item = {
        type: 'customRisk',
        text: 'Do you have an additional risk to add?',
        id: 'custom-new'
      };
      const x: Array<WizardStep> = [{
        element: getComponent(item),
        name: 'custom-new',
        item: item
      }];
      setSteps(s.concat(c).concat(x));
    }
  }, [getFratStatus.data, wizardAnswers, customRisks]);

  useEffect(() => {
    if(steps.length > 0) {
      const item = steps[stepNumber].item;
      isValid(item) ? setNextActive(true) : setNextActive(false);
    } else {
      setNextActive(false);
    }
  }, [stepNumber, wizardAnswers]);

  useEffect(() => {
    console.log(customRisks);
  }, [customRisks]);

  if (createAsmtStatus.error) return <p>CreateAsmtStatus error</p>;

  if (createAsmtInputStatus.error) return <p>CreateAsmtInput error</p>;

  if (updateAsmtStatus.error) return <p>UpdateAsmt error</p>;

  if (getFratStatus.loading) return <p>Loading...</p>;
  if (getFratStatus.error) return <p>Error :(</p>;

  const saveAnswer = function(body: any) {
    createAsmtInput({
      variables: body,
      refetchQueries: ['AssessmentsShow']
    });
  }

  const onSingleChoiceItemChange = function (item: any, option: any) {
    // option is the option id
    if(asmtId === 0) { return }
    setWizardAnswers({ ...wizardAnswers, [item.id]: option });
    saveAnswer({ asmtId: asmtId, fields: {}, itemId: item.id, optionIds: [option], selected: null });
  };

  const onMultipleChoiceItemChange = function (item: any, options: any) {
    // options are the option ids
    if(asmtId === 0) { return }
    setWizardAnswers({ ...wizardAnswers, [item.id]: options });
    saveAnswer({ asmtId: asmtId, fields: {}, itemId: item.id, optionIds: options, selected: null });
  };

  const onTagItemChange = function (item: any, option: any) {
    // option is yes/no
    if(asmtId === 0) { return }
    setWizardAnswers({ ...wizardAnswers, [item.id]: (option === 'yes') });
    saveAnswer({ asmtId: asmtId, fields: {}, itemId: item.id, optionIds: null, selected: (option === 'yes') });
  };

  const onSliderItemChange = function (item: any, option: any) {
    // option is the value
    if(asmtId === 0) { return }
    if(typeof item.id === 'string' && item.id.indexOf('custom') > -1) {
      const risks = [...customRisks];
      const index = item.id.replace('custom-score-', '');
      console.log('setting', index);
      risks[index].score = option;

      const customItems = risks.map((risk) => {
        return {
          text: risk.text,
          score: risk.score
        };
      });

      updateAsmt({
        variables: { id: asmtId, fields: {
          customItems: customItems
        } },
        refetchQueries: ['AssessmentsShow']
      });

      setWizardAnswers({ ...wizardAnswers, [item.id]: option });

      setNextActive(true);
      setCustomRisks(risks);
    } else {
      setWizardAnswers({ ...wizardAnswers, [item.id]: option });
      saveAnswer({ asmtId: asmtId, fields: { score: option }, itemId: item.id, optionIds: null, selected: null });
    }
  };

  const onCustomRiskItemChange = function (item: any, option: any) {
    setWizardAnswers({ ...wizardAnswers, [item.id]: option });

    const risks = [...customRisks];
    const index = item.id.replace('custom-text-', '');
    console.log('setting', index);
    risks[index].text = option;

    setNextActive(option.length > 0);
    setCustomRisks(risks);
  };

  const onEnableDisable = function(value: boolean) {
    if(value) {
      setCustomRisks([...customRisks, {
        text: '',
        score: 0
      }]);
    } else {
      setNextActive(true);
    }
  };

  const saveFrat = function () {
    navigate(`/flights/${flightId}/assessments/${asmtId}`);
  }

  const changeStep = function(step: number) {
    setStepNumber(step);
  }

  const getType = function(item: any) {
    switch (item.type) {
      case 'select':
        return (item.multiple === 1) ? 'singleChoice' : 'multipleChoice';
      case 'tag':
        return 'tag';
      case 'slider':
        return 'slider';
      case 'customRisk':
        return 'customRisk';
      default:
        throw Error(`Unknown type for ${item.id} ${item.type}`);
    }
  }

  const isValid = function(item: any) {
    const type = getType(item);
    const answer = wizardAnswers[item.id];
    if(!item.required) {
      return true;
    }
    if (type === 'singleChoice') {
      return answer ? true : false;
    } else if(type === 'multipleChoice') {
      return answer.length > 0;
    } else if(type === 'tag') {
      return typeof answer !== 'undefined';
    } else if(type === 'slider') {
      return answer ? true : false;
    } else if(type === 'customRisk') {
      return (answer && answer.length > 0) ? true : false;
    } else {
      throw Error(`Unknown validity check for ${item.id} ${item.type}`);
    }
  }

  const getComponent = function(item: any) {
    const type = getType(item);
    if (type === 'singleChoice') {
      return <SingleChoiceItem key={item.id} item={item} answer={wizardAnswers[item.id]} onChange={(option: any) => onSingleChoiceItemChange(item, option)} />;
    } else if(type === 'multipleChoice') {
      return <MultipleChoiceItem key={item.id} item={item} answer={wizardAnswers[item.id]} onChange={(option: any) => onMultipleChoiceItemChange(item, option)} />;
    } else if(type === 'tag') {
      return <TagItem key={item.id} item={item} answer={wizardAnswers[item.id]} onChange={(option: any) => onTagItemChange(item, option)} />;
    } else if(type === 'slider') {
      return <SliderItem key={item.id} item={item} answer={wizardAnswers[item.id]} onChange={(option: any) => onSliderItemChange(item, option)} />;
    } else if(type === 'customRisk') {
      return <CustomRiskItem key={item.id} item={item} answer={wizardAnswers[item.id]} onEnableDisable={onEnableDisable}
                              onChange={(option: any) => onCustomRiskItemChange(item, option)} />;
    } else {
      throw Error(`Unknown component for ${item.id} ${item.type}`);
    }
  }

  return (
    <Wizard steps={steps} stepNumber={stepNumber} onComplete={saveFrat} showNav={true}
            backLink={isLoggedIn() ? `/flights/${flightId}/frats` : '/flights'} onChangeStep={changeStep} nextActive={nextActive} />
  );
}

export default FratShow
