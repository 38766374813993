export const isLoggedIn = function() {
  const val = localStorage.getItem('logged-in');
  return val && val === "1";
}

export class Auth {
  check(success: () => (void), failure: () => (void)) {
    const loggedIn = localStorage.getItem('logged-in');
    if(loggedIn && loggedIn === "1") {
      success();
    } else {
      failure();
    }
    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/ok`, {
      credentials: 'include'
    })
      .then(this.handleErrors)
      .then(res => res.json())
      .then(result => {
        localStorage.setItem('logged-in', '1');
        success();
      }).catch(e => {
        failure();
        localStorage.setItem('logged-in', '0');
      });
  }

  handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }
}
