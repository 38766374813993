import { useMutation } from "@apollo/client";
import { DesktopDatePicker, MobileTimePicker } from "@mui/lab";
import { Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FLIGHTS_CREATE } from "../../ApiQueries";
import Wizard, { WizardStep } from "../Wizard";

const FlightNew = (props: any) => {
    const navigate = useNavigate();

    const [stepNumber, setStepNumber] = useState(0);

    const [nextActive, setNextActive] = useState(false);

    let [formContent, setFormContent] = useState<any>({
        description: '',
        date: (new Date()).toISOString().split('T')[0],
        reg: '',
        dep: '',
        dest: '',
        altn: '',
        eobt: 0,
        eet: 0,
        pob: 0,
        pic: ''
    });

    const addField = function(name: string, value: any) {
        const updatedValue = {
            [name]: value
        };
        setFormContent((formContent: any) => ({
            ...formContent,
            ...updatedValue
        }));
    };

    const handleChange = function(e: any) {
        if(e.target) {
            const wrapper = e.target.closest("[data-name]");
            const name = wrapper.getAttribute("data-name");
            const type = wrapper.getAttribute("data-type");
            if(type === "int") {
                const parsed = parseInt(e.target.value, 10);
                if(Number.isNaN(parsed)) {
                    addField(name, 0);
                } else {
                    addField(name, parsed);
                }
            } else {
                addField(name, e.target.value);
            }
        } else {
            console.log("Unknown name", e)
            throw Error("Unknown name");
        }
    };

    const handleDateChange = function(value: any) {
        const offset = value.getTimezoneOffset();
        const newDate = new Date(value.getTime() - (offset*60*1000));
        addField('date', newDate.toISOString().split('T')[0]);
    };

    const handleEobtAccept = function(value: any) {
        const duration = value.getHours() * 60 + value.getMinutes();
        addField('eobt', duration);
    }

    const handleEetAccept = function(value: any) {
        const duration = value.getHours() * 60 + value.getMinutes();
        addField('eet', duration);
    }

    const handleEobtChange = function() {}
    const handleEetChange = function() {}

    let eobt = new Date(0);
    const offset = eobt.getTimezoneOffset();
    eobt.setUTCSeconds(formContent.eobt * 60 + offset * 60);

    let eet = new Date(0);
    eet.setUTCSeconds(formContent.eet * 60 + offset * 60);

    const [addFlight, { loading, error }] = useMutation(FLIGHTS_CREATE);

    const saveFlight = function() {
        addFlight({
            variables: formContent,
            refetchQueries: ['FlightsIndex', 'FlightsShow'],
            onCompleted: function onCompleted(response) {
                navigate(`/flights/${response.newFlight.id}`);
                window.location.reload();
            }
        });
    };

    useEffect(() => {
        const inputName = steps[stepNumber].name;
        const inputValue = formContent[inputName];
        if(inputValue === 0 || inputValue === "") {
            setNextActive(false)
        } else {
            setNextActive(true);
        }
    }, [stepNumber, formContent]);

    if (loading) return <div>Submitting...</div>;
    if (error) return <div>Submission error! {error.message}</div>;

    const changeStep = function(step: number) {
        setStepNumber(step);
    }

    const steps: Array<WizardStep> = [
        {
            element: <Stack spacing={2}>
                        <Typography variant="body1">Type a description of the flight</Typography>
                        <TextField data-name="description" label="Description" variant="outlined" defaultValue={formContent.description} onChange={handleChange} />
                    </Stack>,
            name: 'description'
        }, {
            element: <Stack spacing={2}>
                        <Typography variant="body1">Select the date of your flight</Typography>
                        <DesktopDatePicker
                            data-name="date"
                            label="Date of flight"
                            inputFormat="yyyy-MM-dd"
                            value={formContent.date}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Stack>,
            name: 'date'
        }, {
            element: <Stack spacing={2}>
                        <Typography variant="body1">Set the aircraft registration</Typography>
                        <TextField data-name="reg" label="Tail number" variant="outlined" value={formContent.reg} onChange={handleChange} />
                    </Stack>,
            name: 'reg'
        }, {
            element: <Stack spacing={2}>
                        <Typography variant="body1">Select the departure airport</Typography>
                        <TextField data-name="dep" label="Departure" variant="outlined" value={formContent.dep} onChange={handleChange} />
                    </Stack>,
            name: 'dep'
        }, {
            element: <Stack spacing={2}>
                        <Typography variant="body1">Select the destination airport</Typography>
                        <TextField data-name="dest" label="Destination" variant="outlined" value={formContent.dest} onChange={handleChange} />
                    </Stack>,
            name: 'dest'
        }, {
            element: <Stack spacing={2}>
                        <Typography variant="body1">Select the alternate airport</Typography>
                        <TextField data-name="altn" label="Alternate" variant="outlined" value={formContent.altn} onChange={handleChange} />
                    </Stack>,
            name: 'altn'
        }, {
            element: <Stack spacing={2}>
                        <Typography variant="body1">Set the estimated off-block time</Typography>
                        <MobileTimePicker
                            data-name="eobt"
                            ampm={false}
                            label="Off-block time"
                            value={eobt}
                            onChange={handleEobtChange}
                            onAccept={handleEobtAccept}
                            renderInput={(params: any) => <TextField {...params} />}
                        />
                    </Stack>,
            name: 'eobt'
        }, {
            element: <Stack spacing={2}>
                        <Typography variant="body1">Set the estimated enroute time</Typography>
                        <MobileTimePicker
                            data-name="eet"
                            ampm={false}
                            label="Enroute time"
                            value={eet}
                            onChange={handleEetChange}
                            onAccept={handleEetAccept}
                            renderInput={(params: any) => <TextField {...params} />}
                        />
                    </Stack>,
            name: 'eet'
        }, {
            element: <Stack spacing={2}>
                        <Typography variant="body1">Set the number of persons on board</Typography>
                        <TextField data-type="int" data-name="pob" label="Persons on board" variant="outlined" value={formContent.pob} onChange={handleChange} />
                    </Stack>,
            name: 'pob'
        }, {
            element: <Stack spacing={2}>
                        <Typography variant="body1">Set the pilot in command</Typography>
                        <TextField data-name="pic" label="Pilot in command" variant="outlined" value={formContent.pic} onChange={handleChange} />
                    </Stack>,
            name: 'pic'
        }
    ]

    return (
        <Wizard steps={steps} stepNumber={stepNumber} showNav={true} onComplete={saveFlight} onChangeStep={changeStep}
                backLink="/flights" nextActive={nextActive} />
    );
}

export default FlightNew
